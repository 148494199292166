<template>
  <!-- 附近的人 -->
  <div class="Fujin">
    <Head :name = 'headerName' :showBack='true'></Head>
    <div class="wrap">
      <div class="location">
        <span><img src="@/assets/img/address.png" alt="">当前位置：{{address.province}}{{address.city}}{{address.district}}</span>
        <span @click="toBaidu">选择位置<i></i></span>
      </div>
      <div class="list">
        <div class="box" v-for="(item,index) in list" :key="index">
          <img :src="item.avatar == '' ? require('@/assets/img/noface.png'):item.avatar" alt="">
          <div class="text">
            <span>姓名：{{item.surname}}{{item.name}}</span>
            <span>电话：{{item.phone == null ? '无':item.phone}}</span>
            <span class="address">地址：{{item.address == null ? '无':item.address}}</span>
          </div>
          <div class="juli">距离{{item.distance}}km</div>
          <div class="btn" @click="run(item.lng,item.lat,item.address)">导航</div>
        </div>
      </div>
      <div class="nodata" v-if="nodata">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerName: '附近的人',
      msg: '', // 提示内容
      showAlert: false, // 提示显示
      ifload: false, // loading
      address: {},
      district: '',
      lng: '', // 经度
      lat: '', // 纬度
      nodata: false, // 无数据
      list: []
    }
  },
  mounted () {
    let geolocation = new window.BMap.Geolocation()
    geolocation.enableSDKLocation(); //允许SDK辅助
    geolocation.getCurrentPosition((r)=>{
      this.address = r.address;
      this.lng = r.longitude;
      this.lat = r.latitude;
      this.$post('/index/nearby',{lng: this.lng,lat: this.lat},(res)=>{
        if (res.data.code == 1) {
          if (res.data.data.length == 0) {
            this.nodata = true;
          } else {
            this.list = res.data.data;
          }
          if (localStorage.getItem('lng') != undefined) {
            this.lng = localStorage.getItem('lng');
            this.lat = localStorage.getItem('lat');
            var point = new window.BMap.Point(this.lng,this.lat);
            var gc = new window.BMap.Geocoder();
            gc.getLocation(point, (rs)=>{
              var addComp = rs.addressComponents;
              this.address = addComp;
              this.$post('/index/nearby',{lng: this.lng,lat: this.lat},(res)=>{
                if (res.data.code == 1) {
                  if (res.data.data.length == 0) {
                    this.nodata = true;
                  } else {
                    this.list = res.data.data;
                  }
                } else {
                  this.msg = res.data.msg;
                  this.showAlert = true;
                }
              })
            });
          }
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
        }
      })
    })
  },
  methods: {
    // 去地图
    toBaidu () {
      this.$router.push({name: 'Baidu',query:{lng: this.lng,lat: this.lat}})
    },
    // 导航
    run (lng,lat,address) {
      window.location.href = `http://api.map.baidu.com/marker?location=${lat},${lng}&title=目标位置&content=${address}&output=html`;
    }
  }
}
</script>

<style lang='scss' scoped>
.Fujin {
  .wrap {
    position: absolute;
    top: 0;
    width: 100%;
    .location {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background: white;
      font-size: 14px;
      span {
        display: flex;
        align-items: center;
        img {
          width: 30px;
          margin-right: 10px;
        }
        i {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-right: 1px solid #000;
          border-bottom: 1px solid #000;
          transform: rotate(-45deg);
        }
      }
    }
    .list {
      padding: 10px;
      .box {
        position: relative;
        height: 60px;
        display: flex;
        padding: 10px;
        background: white;
        img {
          width: 60px;
          height: 60px;
          margin-right: 10px;
          border-radius: 50%;
        }
        .text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          font-size: 14px;
          .address {
            width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .juli {
          position: absolute;
          top: 10px;
          right: 10px;
          color: #999;
          font-size: 12px;
        }
        .btn {
          position: absolute;
          right: 10px;
          bottom: 10px;
          width: 60px;
          height: 25px;
          background: #AB0404;
          color: white;
          font-size: 14px;
          border-radius: 10px;
          text-align: center;
          line-height: 25px;
        }
      }
    }
    .nodata {
      text-align: center;
      color: #999;
      font-size: 20px;
    }
  }
}
</style>